.form-login {
  min-height: 100vh;
  min-width: 288px;
  color: rgb(0, 0, 0);
  /* Estilo por defecto para otras vistas */
  background: linear-gradient(
    199.38deg,
    var(--color-violet-white) -8.82%,
    #45348e 19.5%
  );
  background-blend-mode: multiply;
}

/* Estilo específico cuando está en la página de login */
.login-page .form-login {
  background-color: #fff;
}

form {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  display: block;
  width: 100%;
  text-align: left;
  color: white;
}

input {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  padding: 0 10px;
  font-weight: 500;
  color: #4d4d4d;
  transition: all 150ms ease-in-out;

  border-radius: 5px;
  min-width: 40px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #392b75;
  }

  &:focus {
    border: 2px solid #392b75;
  }
}

input::placeholder {
  color: rgb(255, 255, 255);
}

.btn {
  border-radius: 8px;
  min-height: 40px;
  text-align: center;
  white-space: nowrap;
  border: none;
  background: #937cf4;
  color: #fff;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  padding: 0 35px;
  width: 80%;
  margin-top: 26px;
}

@media screen and (min-width: 600px) {
  .btn {
    width: 400px;
  }
}

.formgroup {
  width: 80%;
  display: inline-block;
  margin: 10px 0;
}

@media screen and (min-width: 600px) {
  .formgroup {
    width: 400px;
  }
}

.inputError {
  border: 1px solid red;
}

.labelError {
  color: rgb(255, 0, 0);
}

.textError {
  color: rgb(255, 0, 0);
  margin-top: -8px;
}

.iconPassword {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

#password::-ms-reveal,
#password::-ms-clear {
  display: none;
}
