.card {
  display: flex;
  flex-direction: column;
}

.CardTitle {
  color: #45348e;
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: capitalize;
}

.CardContent {
  display: flex;
  align-items: center;
  margin: 4px 0;
  color: #45348e;
}

.createdBy {
  color: #45348e;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.hidden {
  display: none;
}
