.card {
  display: flex;
  flex-direction: column;
}

.CardTitleHistorial {
  color: #fff;
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: capitalize;
  line-height: 17px;
}

.CardContentHistorial {
  display: flex;
  align-items: center;
  margin: 4px 0;
  color: #fff;
}

.createdByHistorial {
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.finalizacion {
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
}
