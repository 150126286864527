.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 730px;
  min-width: 50px;
}

.inputLabel {
  display: flex;
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  white-space: nowrap;
}

.inputError {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

.inputRequired {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 3px;
}

.inputContent {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
}

.inputPrefix {
  display: flex;
  align-items: center;
  color: #dadada;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}

.inputSuffix {
  display: flex;
  align-items: center;
  color: #dadada;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
}

.inputSuffix img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.inputText {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  padding: 0 10px;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-weight: 500;
  color: #4d4d4d;
  transition: all 150ms ease-in-out;

  border-radius: 5px;
  min-width: 60px;

  &:hover {
    border: 1px solid #392b75;
  }

  &:focus {
    border: 2px solid #392b75;
  }
}

.inputText::placeholder {
  color: #b3b3b3;
}

.inputTextPrefix {
  padding-left: 30px;
}

.inputTextSuffix {
  padding-right: 30px;
}

.inputTextDisabled {
  margin: 1px solid #dadada;
}

.inputTextError {
  border: 1px solid red;
}
