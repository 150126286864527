:root {
  --MAX_HEIGHT_GLOBAL_STYLES: 850px;
}

:root {
  --BORDER-RADIUS: 0.625rem;
  --BORDER-WEIGHT: 0.12rem;
  --BORDER-WEIGHT-FOCUS: 0.18rem;
  --BORDER-WEIGHT-ACTIVE: 0.18rem;
  --BORDER-WEIGHT-HOVER: 0.18rem;
}
