.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Nav {
  position: relative;
  background: #f2efff;
  background-blend-mode: multiply;
  z-index: 200;
}

.Nav > input {
  margin: 0.5rem 3rem;
  width: -webkit-fill-available;
}

.header > p {
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 0px;
  display: inline;
}

.header {
  display: flex;
  padding: 25px;
  justify-content: space-between;
  color: #45348e;
  background-color: #f2efff;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-center {
  font-weight: bold;
}

.header-center > h1 {
  font-weight: bold;
  margin: 0 10px;
}

.header-right {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.subTitles {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  font-weight: 700;
  color: #45348e;
  padding: 0rem 3rem;
  text-align: center;
}

.body-navbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc((100vh - 2rem) - 75px);
  /*  2rem = icons heigth, 75px = padding icons + navbarbottom*/
  background-color: rgb(255, 255, 255);
  z-index: 400;
}

.button {
  width: 80%;
  height: 50px;
  margin-block: 10px;
  cursor: pointer;
}

.active {
  background: #937cf4;
  color: rgb(255, 255, 255);
}

/* Agregamos este nuevo selector para cambiar el color del icono cuando está activo */
.active svg {
  color: white !important;
}

.border-bottom {
  width: 100%;
  height: 25px;
  background: rgb(255, 255, 255);
  border-radius: 999px 999px 0px 0px;
  bottom: -3px;
  position: relative;
}

.navButton {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  display: flex;
  border: 1px solid #937cf4;
  border-radius: 4px;
  padding: 20px;
  gap: 1rem;
}

.search-input {
  /* Color del texto del placeholder */
  color: rgba(0, 0, 0, 0.5);
  /* Opcional: ajustar opacidad para que no sea tan oscuro */
  /* opacity: 0.7; */
}

/* Para asegurar compatibilidad con diferentes navegadores */
.search-input::-webkit-input-placeholder {
  /* Color del texto del placeholder */
  color: rgba(0, 0, 0, 0.5);
  /* Opcional: ajustar opacidad para que no sea tan oscuro */
  opacity: 0.7;
}
