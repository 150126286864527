/* Scrollbar */
*::-webkit-scrollbar {
  width: 16px;
}
*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: none;
}

*::-webkit-scrollbar-track:hover {
  background-color: none;
}

*::-webkit-scrollbar-track:active {
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  border: 5px solid white;
  border-radius: 99px;
  background-color: rgba(172, 172, 172, 0.473);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(172, 172, 172, 0.473);
}

*::-webkit-scrollbar-thumb:active {
  background-color: rgba(172, 172, 172, 0.473);
}

@media screen and (width <= 900px) {
  *::-webkit-scrollbar {
    display: none;
  }
}
