:root {
  --font-size-base: 16px;
  --font-size-label: clamp(0.8rem, 1vw + 1rem, 0.9rem);

  /* Encabezados */
  --font-size-h1: clamp(
    2.5rem,
    3vw + 1.5rem,
    3rem
  ); /* Tamaño de fuente para h1 */
  --font-size-h2: clamp(
    2rem,
    2.5vw + 1rem,
    2.5rem
  ); /* Tamaño de fuente para h2 */
  --font-size-h3: clamp(
    1.75rem,
    2vw + 0.75rem,
    2rem
  ); /* Tamaño de fuente para h3 */
  --font-size-h4: clamp(
    1.5rem,
    1.75vw + 0.5rem,
    1.5rem
  ); /* Tamaño de fuente para h4 */
  --font-size-h5: clamp(
    1.25rem,
    1.5vw + 0.25rem,
    1rem
  ); /* Tamaño de fuente para h5 */
  --font-size-h6: clamp(
    1rem,
    1.25vw + 0.25rem,
    0.5rem
  ); /* Tamaño de fuente para h6 */
  --font-size-body: clamp(1rem, 1.1vw + 0.2rem, 1.125rem);
  --font-size-title: clamp(1.5rem, 1.75vw + 0.5rem, 1.25rem);
  --font-size-reading: clamp(0.8rem, 0.9rem, 0.9rem);
  --font-size-nav: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);
  --font-size-small: clamp(0.875rem, 1vw, 1rem);
  --font-size-large: clamp(1rem, 1.25vw + 0.25rem, 1.5rem);
  --font-size-button: clamp(1rem, 1.1vw + 0.1rem, 1.125rem);
}
