:root {
  --Z-INDEX-0: 0;
  --Z-INDEX-20: 20;
  --Z-INDEX-40: 40;
  --Z-INDEX-60: 60;
  --Z-INDEX-80: 80;
  --Z-INDEX-100: 100;
  --Z-INDEX-120: 120;
  --Z-INDEX-140: 140;
  --Z-INDEX-160: 160;
  --Z-INDEX-180: 180;
  --Z-INDEX-200: 200;
}
