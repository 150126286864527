:root {
  /*PRIMARY*/
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE: #1a0063;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-25: #1a006340;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-50: #1a006380;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE-75: #1a0063bf;

  /*DARK ON BASEPLATE*/
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE: #f4eff4;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-25: #f4eff425;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-50: #f4eff480;
  --CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE-75: #f4eff4bf;

  /* LIGHT BASEPLATE */
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE: #fefbff;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-25: #fefbff40;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-50: #fefbff80;
  --CARPENTER-VIUTI-CORE-COLOR-LIGHT-BASEPLATE-75: #fefbffbf;
}
