a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover,
a:active,
a:focus {
  text-decoration: underline;
  outline: 0;
}
