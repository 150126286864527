* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

@import url("./normalizer/common.css");
@import url("./normalizer/form.css");
@import url("./normalizer/links.css");

html,
body,
main {
  height: 100%;
}

p,
a {
  font-size: 0.88rem;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

select {
  height: 38px;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

button {
  font-family: "Mulish", sans-serif;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

/* Media queries */

@media (height <= VAR(--MAX_HEIGHT_GLOBAL_STYLES)) {
  p,
  span {
    font-size: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h4,
  button {
    font-size: 0.87rem;
  }
}

html {
  overflow: hidden;
  font-size: 16px; /* Tamaño base para navegadores de escritorio */
}

@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}
