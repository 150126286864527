.containerHistorial {
	margin: 0 0 30px 0;
}
.containerCantidades {
	display: flex;
	padding-top: 20px;
}

.containerTotales {
	display: flex;
	padding-top: 7px;
	margin-bottom: 10px;
}

.cardEstadistica {
	width: 50%;
	border: 1px solid var(--color-secondary);
	margin: 0px 10px 0px 0px;
	border-radius: 5px;
}

.textoEstadistica {
	padding: 10px;
	margin-bottom: 4px;
	line-height: 24px;
	text-align: center;
	font-weight: bold;
	background: -webkit-linear-gradient(271.23deg, #b554cc -3.94%, #45348e 104.68%);
	-webkit-text-fill-color: #fff;
	font-size: 16px;
}

.textoDetalle {
	margin: 3px 0 3px 0;
	color: var(--color-secondary);
	text-align: center;
}
