/* Form inputs and buttons */
input,
button,
textarea,
select {
  font-family: inherit;
  font-size: 0.88rem;
  line-height: inherit;
  color: inherit;
  vertical-align: middle;
  -webkit-appearance: none; /* Remove iOS and WebKit styles */
  appearance: none; /* Define standard property for compatibility */
  border-radius: 0;
  border: none;
  padding: 0;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  background-color: #f8f8f8;
  cursor: pointer;
}

textarea {
  overflow: auto;
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield; /* Define standard property for compatibility */
  appearance: textfield; /* Define standard property for compatibility */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"] {
  border: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="date"]::-ms-clear,
input[type="date"]::-ms-reveal {
  display: none;
} */

input[type="”date”"]::-webkit-datetime-edit-month-field,
input[type="”date”"]::-webkit-datetime-edit-day-field,
input[type="”date”"]::-webkit-datetime-edit-year-field,
input[type="”date”"]::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

input[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
}
