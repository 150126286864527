.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.customSelect {
  max-width: 730px;
  min-width: 90px;
}

.inputLabel {
  display: flex;
  color: #4d4d4d;
  font-size: var(--font-size-label);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputError {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
}

.inputRequired {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 2px;
  display: inline;
}

.inputContent {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
}

.inputPrefix {
  display: flex;
  align-items: center;
  color: #dadada;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}

.inputText {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  padding: 0 10px;
  font-size: var(--font-size-reading);
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: #4d4d4d;
  transition: all 150ms ease-in-out;

  border-radius: 5px;
  min-width: 60px;

  &:hover {
    border: 1px solid #392b75;
  }

  &:focus {
    border: 2px solid #392b75;
  }
}

.inputTextPrefix {
  padding-left: 30px;
}

.inputTextDisabled {
  margin: 1px solid #dadada;
}

.inputErrorContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}
