.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 730px;
  min-width: 40px;
}

.inputLabel {
  display: flex;
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inputErrorContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}

.inputErrorMessage {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 4px;
}
.loadingMessage {
  font-family: "Mulish", sans-serif;
  color: #dadada;
  font-size: 12px;
  margin-left: 4px;
}
.inputRequired {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 3px;
}

.inputContent {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
}

.inputPrefix {
  display: flex;
  align-items: center;
  color: #dadada;
  font-size: var(--font-size-reading);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}

.inputSuffix {
  display: flex;
  align-items: center;
  color: #dadada;
  font-size: var(--font-size-reading);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
}

.inputText {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  padding: 0 10px;
  font-size: var(--font-size-reading);
  font-weight: 500;
  color: #4d4d4d;
  transition: all 150ms ease-in-out;

  border-radius: 5px;
  min-width: 40px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #392b75;
  }

  &:focus {
    border: 2px solid #392b75;
  }
}

.inputText[type="number"]::-webkit-inner-spin-button,
.inputText[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.inputText[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.inputText::placeholder {
  color: #b3b3b3;
}

.inputTextPrefix {
  padding-left: 30px;
}

.inputTextSuffix {
  padding-right: 30px;
}

.inputTextDisabled {
  border: 1px solid #dadada;
}

.inputTextError {
  border: 1px solid red;
}

.rightIcon {
  position: absolute;
  right: 10px;
  top: 13px;
  width: 14px;
  height: auto;
  filter: grayscale(20%) brightness(120%);
}

.leftIcon {
  position: absolute;
  left: 10px;
  top: 13px;
  width: 14px;
  height: auto;
  filter: grayscale(20%) brightness(120%);
}
