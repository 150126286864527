.inputDateContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 730px;
  min-width: 50px;
}

.inputLabel {
  position: relative;
  display: flex;
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  white-space: nowrap;
}

.inputRequired {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 3px;
}

.inputError {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 4px;
}

.inputErrorContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}

.inputErrorMessage {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 4px;
}

.inputDate {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada !important;
  border-radius: 5px !important;
  padding: 0 10px;
  font-size: clamp(0.6rem, 0.88rem, 0.9rem);
  cursor: text;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: brightness(0) invert(0.2) sepia(0.9) hue-rotate(210deg)
      saturate(600%);
  }

  &:hover {
    border-color: #45348e;
    transition: all 150ms ease-in-out;
  }

  &:focus {
    outline: 2px solid #45348e;
  }
}

.inputTextError {
  border: 1px solid red !important;
}

.inputTextDisabled {
  border: 1px solid #dadada;
}
